.contenido_palabras{
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .audio{
        margin-top: 70px;
        width: 400px;
        height: 100px;
        background-color: rgb(181, 117, 241);
        border-radius: 10px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .plabras_respuestas{
        margin-top: 50px;
        width: 80%;


        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        img{
            width: 20%;
            margin: 2%;
            &:hover{
                cursor: pointer;
            }
        }
    }

    .palabraSelecciona {
        opacity: 0.5;
    }

    .botones{
        width: 70%;
        margin-top: 50px;
        
        display: flex;
        flex-direction: row;
        justify-content: center;

        .enlace{
            width: 20%;
            margin-left: 2%;
            margin-right: 2%;
            img{
                width: 100%;
            &:hover{
                cursor: pointer;
                opacity: 0.7;
            }
        }
        }

        img{
            width: 20%;
            margin-left: 2%;
            margin-right: 2%;

            &:hover{
                cursor: pointer;
                opacity: 0.7;
            }
        }
    }

}