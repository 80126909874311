.popUp_correcto{
    position: absolute;
    // margin-top: -500px;
    // visibility: hidden;
    width: 40%;
    z-index: 100;
    // margin-left: 30%;
    display: flex;
    flex-direction: column;

    .ventana{
        width: 100%;
    }

    .btn_cerrar{
        width: 35%;
        margin-top: -20%;
        margin-left: 32.5%;

        &:hover{
            cursor: pointer;
            opacity: 0.6;
        }
    }
}